import React from 'react';
import { Footer } from '../footer/Footer';

const FareStyle = {
    Cell: { border: '2px solid #000000', color: '#adadad' },
    HeaderRow: { backgroundColor: "#000", color: "#adadad", border: "2px solid #000" },
    CellText: { color: "#adadad", fontSize: 18 },
}

export function Fares() {
    return (
        <>
            <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title">Fares</h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">At Manchester Airport Taxi Service, we offer competitive and transparent fares for your airport transportation needs. Our pricing structure is designed to provide you with flexibility and value, considering factors such as distance, duration, and any additional services or requirements you may have.</p>
                        </div>
                    </div>
                    <div className="table-responsive"> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={ FareStyle.Cell } scope="col">Distance</th>
                                    <th style={ FareStyle.Cell } scope="col">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td>First 10 Miles</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>£40</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td>After 10 Miles</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>£3 / Mile (Upto 100 Miles)</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td>After 100 Miles</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>£2 / Mile</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h2>Base Fare</h2>
                            <p className="section-subtitle">The base fare covers the initial distance or time of your journey and includes the cost of dispatching a taxi to your pick-up location. This fare may vary depending on your location and the type of vehicle you choose. Our prices start with your first 10 miles being £40, with every mile after that up to 100 miles being at a rate of £3/mile. This pounds/mile rate then reduces to £2/mile once you pass the 100-mile mark. </p>
                            <h2>Additional Charge</h2>
                            <p className="section-subtitle">We aim to provide a fair and straightforward pricing system. In addition to the base fare, there may be additional charges for the following:</p>
                            <h2>Extra Passengers</h2>
                            <p className="section-subtitle">If you have more passengers than the standard capacity of the vehicle you choose, an additional charge per extra passenger may apply. This ensures that we can provide a comfortable and safe journey for everyone on board.</p>
                            <h2>Luggage</h2>
                            <p className="section-subtitle">We understand that you may have luggage or special equipment that requires additional space or handling. Depending on the size and weight of your luggage, there may be an extra charge to accommodate these items. Rest assured that we prioritize the safety and proper handling of your belongings.</p>
                            <h2>Specific Services</h2>
                            <p className="section-subtitle">For certain specialised services, such as child seats, pet transportation, or wheelchair accessibility, there may be an additional fee. These services require additional resources or equipment to ensure a comfortable and suitable experience for all passengers.</p>
                            <h2>Transparent Pricing and Fare Estimates</h2>
                            <p className="section-subtitle">We believe in transparency and providing you with the best value for your money. To help you estimate the fare for your journey, we offer an online fare calculator on our website. Simply enter your pick-up and drop-off locations, along with any additional requirements, and the fare calculator will provide you with an estimated cost.</p>
                            <p className="section-subtitle">Please note that the fare estimates are approximate and may vary based on traffic conditions, route deviations, or any requested stops during your journey. The final fare will be determined at the end of your trip based on the actual distance travelled and any additional charges that apply.</p>
                            <h2>Contact Us for Further Information</h2>
                            <p className="section-subtitle">If you have any questions about our fares, additional charges, or specific services, our friendly customer support team is available to assist you. Feel free to reach out to us via phone or email, and we will be happy to provide you with the information you need to make an informed decision for your airport transportation needs.</p>
                            <p className="section-subtitle">Choose Manchester Airport Taxi Service for reliable, competitive fares and a comfortable, stress-free journey to or from the airport.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
