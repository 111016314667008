/// <reference types="googlemaps" />
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Autocomplete, DirectionsRenderer, GoogleMap, InfoWindow, LoadScript, Marker, TrafficLayer } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { add, formatDuration, intervalToDuration, differenceInHours, endOfToday, isToday } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useHistory } from "react-router-dom";
import ImgDropoff from '../../../img/book/dropoff.svg';
import ImgPickup from '../../../img/book/pickup.svg';
import ImgUser from '../../../img/book/user.svg';
import ImgDate from '../../../img/book/date.svg';
import ImgAirplane from '../../../img/book/airplane.svg';
import ImgFeatures from '../../../img/book/features.svg';
import ImgMarker from '../../../img/book/marker.svg';
import car from '../../../img/car.svg';
import { ApiService } from '../../../service/api.service';
import { Booking } from '../../entities/booking.entity';
import { Currency } from '../../entities/currency.entity';
import { Passenger } from '../../entities/passenger.entity';
import { Zone } from '../../entities/zone.entity';
import { getLaterDate } from '../../util/date.util';
import { CheckoutForm } from './CheckoutForm';
import { enGB } from 'date-fns/locale';
import { Footer } from '../../footer/Footer';

const MAP_LIBS: Libraries = ['places'];
let directionsService: google.maps.DirectionsService;
interface ServiceList {
   id: string,
   title: string,
   isDefault: boolean,
}

enum Rate {
   Meter = "meter",
   Flat = "flat"
}
enum Status {
   Pickup = "pickup",
   Dropoff = "dropoff",
   Bidding = "bidding",
   Abandoned = "abandoned",
   DriverCancelled = "driver-cancelled",
   PassengerCancelled = "passenger-cancelled",
   AwaitingAccept = "awaiting-accept",
   NotAccepted = "not-accepted",
   DriverEnroute = "driver-enroute",
   DestinationEnroute = "destination-enroute",
   Arrived = "arrived",
   Completed = "completed"
}

interface Driver {
   id: string,
   code: string,
   firstName: string,
   lastName: string,
   gender: string,
   email: string,
   phone: string,
   dateOfBirth: Date,
   businessRegistration: string,
   licenseNumber: string,
   licenseType: string,
   licenseExpiryDate: Date,
   emergencyName: string,
   emergencyEmail: string,
   emergencyPhone: string,
   bankName: string,
   bankAccountNumber: string,
   bankAccountRouting: string,
   bankAccountTitle: string,
   status: string,
   createTime: string,
   updateTime: string,
   deletedTime: string
}

interface Vehicle {
   id: string,
   vin: string,
   plate: string,
   registrationExpiryDate: Date,
   year: string,
   make: string,
   model: string,
   color: string,
   numPassengers: number,
   numLuggage: number,
   ownership: string,
   status: string,
   createTime: string,
   updateTime: string,
   deletedTime: string
}

enum Type {
   Dispatch = "dispatch",
}
enum StepType {
   Personal = "personal",
   OTP = 'otp',
   Stripe = "stripe",
   Finalize = 'finalize',
   Scheduled = 'scheduled'
}

interface Area {
   driver: Driver,
   vehicle: Vehicle,
   lat: number,
   lng: number,
   heading: number,
}

interface Gps {
   GpsNearBy: Area[]
}

interface location {
   lat: number | undefined,
   lng: number | undefined,
   text: string | undefined,
   area: string | undefined,
   city: string | undefined,
   state: string | undefined,
   country: string | undefined,

}

enum Repeat {
   NONE = "none",
   DAILY = "daily",
   WEEKLY = "weekly",
   MONTHLY = "monthly",
   YEARLY = "yearly"
}
type ScheduleDates = { date: string, repeat: Repeat, returnToPickup: boolean };


export function Book() {
   let pickupAutocompleteRef = useRef<Autocomplete>(null);
   let dropoffAutocompleteRef = useRef<Autocomplete>(null);
   let mapAutocompleteRef = useRef<Autocomplete>(null);
   let centerMarkerRef = useRef<Marker>(null);
   // let pickup: any = useRef();
   // let dropoff: any = useRef();

   let history = useHistory();
   const [dropOffMarker, setDropOffMarker] = useState<boolean>(false);
   const [pickupMarker, setPickupMarker] = useState<boolean>(false);
   const [selectedMarker, setSelectedMarker] = useState<boolean>(false);
   const [center, setCenter] = useState<google.maps.LatLng>();
   const [zoom, setZoom] = useState(10);
   const [map, setMap] = useState<google.maps.Map>();
   const [generalCurrency, setGeneralCurrency] = useState<Currency>();
   const [zone, setZone] = useState<Zone>();
   const [pickupPlace, setPickupPlace] = useState<google.maps.places.PlaceResult>();
   const [dropoffPlace, setDropoffPlace] = useState<google.maps.places.PlaceResult>();
   const [mapSearchPlace, setMapSearchPlace] = useState<google.maps.places.PlaceResult>();
   const [directions, setDirections] = useState<google.maps.DirectionsResult>();
   const [pickupPlaceFormatted, setPickupPlaceFormatted] = useState<location>();
   const [dropoffPlaceFormatted, setDropoffPlaceFormatted] = useState<location>();
   const [pickupDate, setPickupDate] = useState<string>();
   const [pickupTime, setPickupTime] = useState<string>();
   const [dropoffDate, setDropoffDate] = useState<string>();
   const [dropoffTime, setDropoffTime] = useState<string>();
   const [pickupDateOpened, setPickupDateOpened] = useState<boolean>(false);
   const [pickupTimeOpened, setPickupTimeOpened] = useState<boolean>(false);
   const [dropoffDateOpened, setDropoffDateOpened] = useState<boolean>(false);
   const [dropoffTimeOpened, setDropoffTimeOpened] = useState<boolean>(false);

   const [scheduledBooking, setScheduledBooking] = useState<boolean>(false);
   const [repeatBooking, setRepeatBooking] = useState<boolean>(false);
   const [routes, setRoutes] = useState<google.maps.DirectionsLeg[]>([]);
   const [options, setOptions] = useState<any[]>([]);
   const [dayOptions, setDayOptions] = useState<any[]>([{ label: "Monday", value: "Monday" }, { label: "Tuesday", value: "Tuesday" }, { value: "Wednesday", label: "Wednesday" }, { value: "Thursday", label: "Thusrday" }, { label: "Friday", value: "Friday" }, { label: "Saturday", value: "Saturday" }, { label: "Sunday", value: "Sunday" }]);
   const [findArea, setFindArea] = useState<Gps>();
   const [serviceId, setServiceId] = useState<string>('');
   const [point, setPoint] = useState<google.maps.Point>();
   const [size, setSize] = useState<google.maps.Size>();
   const [sendData, setSendData] = useState<boolean>(true);
   const [email, setEmail] = useState<string>();
   const [repeatDays, setRepeatDays] = useState<string[]>([]);
   const [phone, setPhone] = useState<string>();
   const [scheduledTimestamp, setScheduledTimestamp] = useState<string>();
   const [repeatEndTime, setRepeatEndTime] = useState<Date>();
   const [name, setName] = useState<string>('');
   const [passenger, setPassenger] = useState<Passenger>();
   const [feature, setFeature] = useState<number>(0);
   const [clientSecret, setClientSecret] = useState<string>();
   const [step, setStep] = useState<StepType>(StepType.Personal);
   const [stripeOptions, setStripeOptions] = useState<any>()
   const [booking, setBooking] = useState<any>();
   const [loading, setLoading] = useState<any>(false);

   const [scheduleDates, setScheduleDates] = useState<ScheduleDates[]>([]);
   const totalBookings = useMemo<number>(() => {
      let bookings = 0;
      for (let { date, repeat, returnToPickup } of scheduleDates) {
         switch (repeat) {
            case Repeat.NONE:
               returnToPickup ? bookings += 2 : bookings += 1;
               break;
            case Repeat.DAILY:
               // MAX 30 daily booking allowed
               returnToPickup ? bookings += 60 : bookings += 30;
               break;
            case Repeat.WEEKLY:
               // MAX 10 weeks allowed
               returnToPickup ? bookings += 20 : bookings += 10;
               break;
            case Repeat.MONTHLY:
               // MAX 6 months allowed
               returnToPickup ? bookings += 12 : bookings += 6;
               break;
            case Repeat.YEARLY:
               // MAX 5 years allowed
               returnToPickup ? bookings += 10 : bookings += 5;
         }
      }

      return bookings;
   }, [scheduleDates]);
   const dateRef = useRef<string>();

   const onChangeDays = (e: any) => {
      const final = e?.map((item: { value: string }) => item.value)
      setRepeatDays(final);
   }

   const stripePromise = loadStripe((process.env.REACT_APP_STRIPE_KEY) as string);

   const getZoneByLocation = async (pickupLocation: google.maps.places.PlaceResult) => {
      if (pickupLocation && pickupLocation.geometry && pickupLocation.geometry.location) {
         const response = await ApiService.post('fare/getZone', {
            lat: pickupLocation.geometry.location.lat(),
            lng: pickupLocation.geometry.location.lng(),
         });
         setGeneralCurrency(response.data.generalCurrency);
         setZone(response.data);
      }
   }

   const onLoad = React.useCallback((map) => {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      setMap(map)

      setTimeout(() => {
         // Manchester
         const DEFAULT_CENTER = new google.maps.LatLng(53.47874561509334, -2.24230186952202);
         const DEFAULT_POINT = new google.maps.Point(32, 32);
         const DEFAULT_SIZE = new google.maps.Size(64, 64);

         setCenter(DEFAULT_CENTER);
         setPoint(DEFAULT_POINT)
         setSize(DEFAULT_SIZE)
         setZoom(12);
      }, 100);

      directionsService = new google.maps.DirectionsService();
      if (pickupAutocompleteRef.current?.state?.autocomplete) { pickupAutocompleteRef.current.state.autocomplete.addListener('place_changed', () => onAddressSelect('pickup')); }
      if (dropoffAutocompleteRef.current?.state?.autocomplete) {
         dropoffAutocompleteRef.current.state.autocomplete.addListener('place_changed', () => onAddressSelect('dropoff'));
      }

   }, [])

   const onClick = (e: google.maps.MapMouseEvent) => {
      e.stop();
      if (map) {
         setMapSearchPlace({ geometry: { location: e.latLng }})
         setCenter(e.latLng);
      }
   }

   const onUnmount = React.useCallback(() => {
      setMap(undefined)
   }, []);

   const onAddressSelect = async (type: 'dropoff' | 'pickup') => {
      const ref = type === 'dropoff' ? dropoffAutocompleteRef : pickupAutocompleteRef;
      const areaTypes = ['locality', 'sublocality_level_1', 'sublocality', 'political'];
      const autocomplete: google.maps.places.Autocomplete | null | undefined = ref.current?.state.autocomplete;
      if (!autocomplete) {
         return
      }
      const place = autocomplete.getPlace();

      if (!place) {
         if (type === 'pickup') {
            setPickupPlace(undefined);
            setPickupPlaceFormatted(undefined);
         } else {
            setDropoffPlace(undefined);
            setDropoffPlaceFormatted(undefined);
         }
         return;
      }
      let city, state, country;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const area = place.address_components?.find((component: any) => {
         for (let areaType of areaTypes) {
            if (component.types.includes(areaType)) {
               return true;
            }
         }
         return false;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      place.address_components?.forEach((component: any) => {
         if ((component?.types?.indexOf("locality") > -1)) {
            city = component.long_name;
         }

         if ((component.types.indexOf("postal_town") > -1)) {
            city = component.long_name;
         }

         if (component.types.indexOf("administrative_area_level_1") > -1) {
            state = component.short_name;
         }

         if (component.types.indexOf("country") > -1) {
            country = component.long_name;
         }
      });


      // let key: string = Object.keys(autocomplete..place)[0];

      type === 'dropoff' ? setDropoffPlaceFormatted({ lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng(), text: place.formatted_address, area: area?.long_name, city: city, country, state })
         : setPickupPlaceFormatted({ lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng(), text: place.formatted_address, area: area?.long_name, city, country, state })

      const setter = type === 'dropoff' ? setDropoffPlace : setPickupPlace;
      setter(place);

      if (type === 'dropoff') {
         setDropoffPlace(place)
         setDropOffMarker(false);
      }
      if (type === 'pickup') {
         setPickupPlace(place);
         setPickupMarker(false);
      }
   }

   const findInArea = async () => {
      try {
         if (pickupPlace && pickupPlace.geometry && pickupPlace.geometry.location) {
            if (sendData === true) {
               const response = await ApiService.post('driver/findInArea', {
                  latitude: pickupPlace.geometry.location.lat().toString(),
                  longitude: pickupPlace.geometry.location.lng().toString(),
                  serviceId: serviceId.toString()
               })
               setFindArea(response.data)
               setSendData(false)
            } else {
               setInterval(async function () {
                  if (pickupPlace && pickupPlace.geometry && pickupPlace.geometry.location) {
                     const response = await ApiService.post('driver/findInArea', {
                        latitude: pickupPlace.geometry.location.lat().toString(),
                        longitude: pickupPlace.geometry.location.lng().toString(),
                        serviceId: serviceId.toString()
                     })
                     setFindArea(response.data)
                  }
               }, 30000)
            }
         } else {
            // console.log('this api will not run until pickupPlace location filled')
         }
      } catch (err) {
         // console.log('Failed to find in area', err)
      }
   }

   useEffect(() => {
      if (scheduledBooking) {
         setScheduledTimestamp(getLaterDate(16));
      } else {
         setScheduledTimestamp(undefined);
      }
   }, [scheduledBooking]);

   useEffect(() => {
      const date = new Date(pickupDate as string);
      const time = new Date(pickupTime as string);
      if (!date || !time || !pickupDate || !pickupTime) {
         return;
      }
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
      setScheduleDates([{ date: date.toISOString(), repeat: Repeat.NONE, returnToPickup: false}]);
   }, [pickupDate, pickupTime])

   useEffect(() => {
      findInArea()
      if (pickupPlace) {
         getZoneByLocation(pickupPlace)
      }
   }, [pickupPlace])

   useEffect(() => {
      if (!phone) return;
      localStorage.setItem('pax-phone', phone);
   }, [phone]);

   const getTotalDistance = () => {
      let totalDistance = 0;
      routes.forEach(route => {
         totalDistance += route.distance ? route.distance.value : 0;
      });

      return totalDistance;
   }

   const getTotalEstimate = () => {
      let totalEstimate = 0;
      routes.forEach(route => {
         totalEstimate += getEstimate(route);
      });

      return totalEstimate;
   }

   const getTotalDuration = () => {
      let totalDuration = 0;
      routes.forEach(route => {
         totalDuration += route.duration_in_traffic ? route.duration_in_traffic.value : 0;
      });

      return totalDuration;
   }

   const getEstimate = (route: google.maps.DirectionsLeg): number => {
      // const response = await ApiService.post('fare/estimate', {
      //    lat: pickupPlace?.geometry?.location.lat(),
      //    lng: pickupPlace?.geometry?.location.lng(),
      //    distance,
      //    duration,
      //    traffic: trafficDuration,
      //    serviceId,
      //    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
      // });

      if (!route.distance) {
         return 0;
      }
      const distanceInMiles = route.distance.value * 0.00062137;
      let distanceAbove10 = 0;
      if (distanceInMiles > 10) {
        if (distanceInMiles - 10 > 90) {
          distanceAbove10 = 90;
        } else {
          distanceAbove10 = distanceInMiles - 10;
        }
      }
      let distanceAbove100 = distanceInMiles > 100 ? distanceInMiles - 100 : 0;
      const value = parseInt(((40 + (distanceAbove10 * 3) + (distanceAbove100 * 2)) * 100) + '', 10);

      return value + feature;
   }

   useEffect(() => {
      refresh();
   }, [])

   const refresh = async () => {
      try {
         const response = await ApiService.get('service/public/list');
         const data: ServiceList[] = await response.data.map((item: any) => ({ id: item.id, title: item.title, isDefault: item.isDefault }));
         setOptions(data)

         // console.log("service", data)
         let service;
         for (let i = 0; i < data.length; i++) {
            // console.log(data[i].isDefault)

            if (data[i].isDefault === true) {
               service = data[i].id
               setServiceId(data[i].id)
            }
         }
         // console.log("service", service)
         if (service === "" || typeof service === 'undefined') {

            setServiceId(data[0].id)

         }

      } catch (err) {
         // console.log('Failed to load fleet list', err);
      }

      const storedPhone = localStorage.getItem('pax-phone');
      if (storedPhone) {
         handlePhone(storedPhone);
      }
   }
   const onChange = (e: any) => {
      // console.log("service id", e.target.value);
      setServiceId(e.target.value);
   }

   useEffect(() => {
      if (!pickupPlace || !dropoffPlace) {
         if (pickupPlace) {
            setCenter(pickupPlace.geometry?.location);
            console.log('PICKUP PLACE', pickupPlace)
            setZoom(15);
         }
         if (dropoffPlace) {
            setCenter(dropoffPlace.geometry?.location);
            console.log('DROPOFF PLACE', pickupPlace)
            setZoom(15);
         }
      }

      // Set departure time to 10 minues from now
      const departureTime = add(new Date(), { minutes: 10, hours: 5 });
      if (directionsService
         && pickupPlace && pickupPlace.geometry && pickupPlace.geometry.location
         && dropoffPlace && dropoffPlace.geometry && dropoffPlace.geometry.location) {
         const directionsOptions: google.maps.DirectionsRequest = {
            origin: pickupPlace.geometry.location,
            destination: dropoffPlace.geometry.location,
            travelMode: google.maps.TravelMode.DRIVING,
            durationInTraffic: true,
            drivingOptions: { departureTime },
         };
         if (dropoffDate && dropoffTime) {
            directionsOptions.destination = directionsOptions.origin;
            directionsOptions.waypoints = [{ location: dropoffPlace.geometry.location, stopover: true }];
         }
         directionsService.route(directionsOptions, (result, status) => {
            if (result && status === google.maps.DirectionsStatus.OK) {
               setDirections(result);
               if (map && !directions) {
                  map.fitBounds(result.routes[0].bounds);
               }

               const routes = result.routes[0].legs
               if (!routes || routes.length === 0) {
                  return;
               }

               setRoutes(routes);

               setMapSearchPlace(undefined);
            }
         })
      }
   }, [pickupPlace, dropoffPlace, serviceId, dropoffDate, dropoffTime]);

   const distanceText = (distance: number | undefined): string => {
      if (!distance) {
         return 'N/A';
      }

      return `${roundTo(distance * 0.00062137)} Mi`;
   }

   const durationText = (duration: number | undefined): string => {
      if (!duration) {
         return 'N/A';
      }
      const epoch = new Date(0)
      const secondsAfterEpoch = new Date(duration * 1000)
      const durationObj = intervalToDuration({
         start: epoch,
         end: secondsAfterEpoch
      })
      return formatDuration(durationObj, { format: ['hours', 'minutes'] });
   }

   const trafficText = (trafficDuration: number | undefined): string => {
      if (!trafficDuration) {
         return 'N/A';
      }
      const epoch = new Date(0)
      const secondsAfterEpoch = new Date(trafficDuration * 1000)
      const durationObj = intervalToDuration({
         start: epoch,
         end: secondsAfterEpoch
      })
      return formatDuration(durationObj, { format: ['hours', 'minutes'] });
   }

   const estimateText = (route?: google.maps.DirectionsLeg): string => {
      const estimate = route ? getEstimate(route) : getTotalEstimate();
      if (!estimate || isNaN(estimate)) {
         return '...';
      }

      const convertedEstimate = estimate / (generalCurrency?.subunits || 1);
      return `${generalCurrency?.symbol || ''}${convertedEstimate.toString()}`;
   }

   const roundTo = (val: number): number => {
      return Math.round((val + Number.EPSILON) * 100) / 100
   }

   const getName = () => {
      const splitName = name.split(' ');
      const firstName = splitName[0];
      let lastName = '';

      if (splitName.length > 1) {
         lastName = splitName.slice(1).join(' ');
      }

      return {
         firstName, lastName,
      }
   }

   const onHandleClick = async (e: any) => {
      e.preventDefault();

      // if (scheduledBooking && (!scheduledTimestamp || !isFifteenMinutesLater(scheduledTimestamp))) {
      //    throw 'Scheduled bookings need to be made at least 15 minutes earlier';
      // }

      setLoading(true);
      try {
         const name = getName();
         const response = await ApiService.post('passenger/createWithStripe', {
            phone,
            name: name.firstName,
            lastName: name.lastName,
            email,
            corporateAccount: false,
            appName: `${process.env.REACT_APP_NAME?.toUpperCase()}`,
         });

         if (response.data?.error) {
            switch (response.data.error.code) {
               case "23505":
                  alert("Combination of email address and phone are already registered !")
                  break;
               default:
                  alert(`Error: ${response.data?.error?.message}`)
            }
         }
         else {
            let passengerData: any;
            if (response.data?.newPassenger || response.data?.passenger) {
               // setNewPassenger(response.data?.newPassenger)
               passengerData = response?.data?.newPassenger ? response?.data?.newPassenger : response.data?.passenger
               setPassenger(passengerData)
               setClientSecret(response.data?.clientSecret)
               const options = {
                  clientSecret: response.data?.clientSecret,
               }
               setStripeOptions(options);
               setStep(StepType.Stripe);
               // setStep(StepType.Finalize); for testing of schedule bookings
            }
            else {
               console.error("no passenger data received ")
            }
         }

         setLoading(false);
      }
      catch (error: any) {
         setLoading(false);
         alert(`Error: ${error?.message ? error.message : error}`)
      }
   }

   const createBooking = async () => {
      try {
         // console.log('createBooking: called createBooking')
         setBooking(undefined);
         if (repeatEndTime && scheduledTimestamp && new Date(scheduledTimestamp) > new Date(repeatEndTime)) {
            alert("invalid end time for repeat booking")
            return;
         }

         const name = getName();
         const { data }: Booking = await ApiService.post('booking/passenger', {
            peerId: '123e4567-e89b-12d3-a456-426614174000',
            firstName: name.firstName,
            lastName: name.lastName,
            service: serviceId,
            pickupAddress: { lat: pickupPlaceFormatted?.lat, lng: pickupPlaceFormatted?.lng, text: pickupPlaceFormatted?.text, area: pickupPlaceFormatted?.area, city: pickupPlaceFormatted?.city, country: pickupPlaceFormatted?.country, state: pickupPlaceFormatted?.state },
            dropOffAddress: { lat: dropoffPlaceFormatted?.lat, lng: dropoffPlaceFormatted?.lng, text: dropoffPlaceFormatted?.text, area: dropoffPlaceFormatted?.area, city: dropoffPlaceFormatted?.city, state: dropoffPlaceFormatted?.state, country: dropoffPlaceFormatted?.country },
            email: email,
            phone: phone,
            isCorporate: false,
            scheduledTimestamp: scheduledBooking ? new Date(scheduledTimestamp!) : undefined,
            repeatEndTime: repeatEndTime,
            distance: getTotalDistance(),
            duration: getTotalDuration(),
            status: Status.Dropoff,
            zone: zone,
            estimate: getTotalEstimate(),
            repeatDays: repeatDays,
            rate: Rate.Meter,
            type: Type.Dispatch,
            countSchedule: totalBookings,
            scheduleDates: scheduleDates.map(({ date, repeat, returnToPickup }) => ({ date: new Date(date).toISOString(), repeat, returnToPickup })),
         });

         localStorage.setItem('flash_message', 'Your booking has been made. You will be notified once it\'s confirmed. Thank you')
         history.push('/')
      } catch (error: any) {
         // console.log('Error in Booking:', error.response.data.message);
         alert(`${error.response.data.message}`)
      }

   }

   const onCenterChange = () => {
      if (!map) return;
      setTimeout(() => {
         // const geocoder = new google.maps.Geocoder();
         // geocoder.geocode({ location: map.getCenter() }, (result) => {
         //    if (!result) return;
         //    // setMapSearchPlace(result[0] as unknown as google.maps.places.PlaceResult);
         //    if (mapAutocompleteRef?.current && mapAutocompleteRef.current.state.autocomplete) {
         //       // mapAutocompleteRef.current.state.autocomplete.set('place', result[0]);
         //    }
         // });
      }, 100);
   }

   const onDrag = () => {
      if (centerMarkerRef.current && centerMarkerRef.current.marker) {
         centerMarkerRef.current.marker.setPosition(map ? map.getCenter() : null);
      }
   }

   const mapSearch = () => {
      const place = mapAutocompleteRef.current?.state.autocomplete?.getPlace();
      setCenter(place?.geometry?.location)
      setZoom(15);
      setMapSearchPlace(place);
   }

   const setSearchAs = (type: 'pickup' | 'dropoff') => {
      const autocompleteRef = type === 'pickup' ? pickupAutocompleteRef : dropoffAutocompleteRef;
      if (map && autocompleteRef?.current && autocompleteRef.current.state.autocomplete) {
         const geocoder = new google.maps.Geocoder();
         geocoder.geocode({ location: map.getCenter() }, (result) => {
            if (!result) return;
            const place = result[0];
            if (autocompleteRef.current && autocompleteRef.current.state.autocomplete) {
               autocompleteRef.current.state.autocomplete.set('place', place);
            }
         });
      }
   }

   const clearPlace = (type: 'pickup' | 'dropoff') => {
      if (type === 'pickup') {
         setPickupPlace(undefined);
         setPickupMarker(false);
         if (pickupAutocompleteRef.current && pickupAutocompleteRef.current.state.autocomplete) {
            pickupAutocompleteRef.current.state.autocomplete.set('place', undefined);
         }
      } else if (type === 'dropoff') {
         setDropoffPlace(undefined);
         setDropOffMarker(false);
         if (dropoffAutocompleteRef.current && dropoffAutocompleteRef.current.state.autocomplete) {
            dropoffAutocompleteRef.current.state.autocomplete.set('place', undefined);
         }
      }
      setDirections(undefined);
   }

   const minDate = () => {
      return add(new Date(), { hours: 6 })
   }

   const minTime = (type: 'pickup' | 'dropoff') => {
      const date = type === 'pickup' ? pickupDate : dropoffDate;
      if (date && isToday(new Date(date))) {
         return minDate();
      }
      return undefined;
   }


   const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

   const onCardAdded = () => {
      setStep(StepType.Finalize);
   }

   const handlePhone = async (value: any) => {
      if (!isValidPhoneNumber(value)) {
         return;
      }
      setPhone(value)
      if (value) {
         var { data } = await ApiService.get(`passenger/public/${value}`)
         // console.log("data phone", data)
         if (data) {
            setName(`${data.firstName} ${data.lastName}`);
            setEmail(data.email)
         }
         else {
            setName("");
            setEmail("");
         }
      }
   }

   const isButtonDisabled = () => {
      // return !estimate || !isValidPhoneNumber(`${phone}`) || (scheduledBooking && (!scheduledTimestamp || !isFifteenMinutesLater(scheduledTimestamp) || (repeatBooking && repeatDays.length <= 0)));
      return !getTotalEstimate() || !isValidPhoneNumber(String(phone)) || scheduleDates.length === 0;
   }

   const handleScheduleRowUpdate = (newValue: any, index: number, key: keyof ScheduleDates | "delete") => {
      const tmp = [...scheduleDates];
      const oldObj = { ...tmp[index] };
      switch (key) {
         case "date":
            if (!checkDateTimeOverlap(newValue, index)) {
               oldObj.date = newValue;
            }
            else { alert('The Date is overlapping with the previous entries!'); return; }
            break;
         case "repeat":
            oldObj.repeat = newValue;
            break;
         case "returnToPickup":
            oldObj.returnToPickup = newValue;
            break;
         case "delete":
            tmp.splice(index, 1);
            setScheduleDates(tmp);
            return;
      }

      tmp.splice(index, 1, oldObj);
      setScheduleDates(tmp);
   }

   /**
    * return true the string overlaps
    * return false if the string does not overlap with scheduleDates
    * @param dateTimeStr 
    * @returns 
    */
   function checkDateTimeOverlap(dateTimeStr: string, idx?: number) {
      const checkDate = new Date(dateTimeStr);
      let overlap = false;
      for (let i = 0; i < scheduleDates.length; i++) {
         if (typeof idx !== undefined && idx === i) continue; // Ignore Self check
         const scheduleDate = new Date(scheduleDates[i].date);
         const diffInMs = Math.abs(scheduleDate.getTime() - checkDate.getTime());
         const diffInMins = Math.floor((diffInMs / 1000) / 60);
         if (diffInMins < 30) {
            overlap = true; break;
         }
      }

      return overlap;
   }

   const nth = function (d: number) {
      const dString = String(d);
      const last = +dString.slice(-2);
      if (last > 3 && last < 21) return d + 'th';
      switch (last % 10) {
         case 1: return d + "st";
         case 2: return d + "nd";
         case 3: return d + "rd";
         default: return d + "th";
      }
   }

   return (
      <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
         <section className="section-yellow home-section book-section" id="home">
            <div className="container">
               <div className="row">
                  <div className="form-container col-md-6" style={{ height: step === StepType.Stripe || step === StepType.Finalize ? 235 : 'auto' }}>
                     {/* <h1>Book your ride</h1>
                     {step !== StepType.Scheduled && (
                        <p>Book your ride with ease and arrive in comfort and style using our simple booking form for the most reliable taxi cab service in Manchester</p>
                     )}
                     {step === StepType.Scheduled && (
                        <p >Schedule your rides before hand and enjoy the comfort of getting rides at the exact moment. You can plan your whole week beforehand with us</p>
                     )} */}

                     <div>
                        <div>
                           <p className="register_success_box" style={{ display: "none" }}>We received your message and you'll hear from us soon. Thank You!</p>
                           {step === StepType.Personal && (
                              <>
                                 <form id="register-form" className="register-form register" method="post" onSubmit={onHandleClick}>

                                    <div className={`form-field ${pickupPlace ? 'checked' : ''}`}>
                                       <img src={ImgPickup} width={64} height={64} />
                                       <div>
                                          <h4>Where do you want to be picked up from?</h4>
                                          <Autocomplete
                                             ref={pickupAutocompleteRef}
                                             fields={['geometry', "address_component", "formatted_address"]}
                                             restrictions={{ country: ['gb'] }}
                                          >
                                             <input className="register-input white-input" autoFocus required placeholder="Pickup (Type here or Pick from Map)" type="search" />
                                          </Autocomplete>
                                       </div>
                                    </div>

                                    <div className={`form-field ${dropoffPlace ? 'checked' : ''}`}>
                                       <img src={ImgDropoff} width={64} height={64} />
                                       <div>
                                          <h4>What is your destination?</h4>
                                          <Autocomplete
                                             ref={dropoffAutocompleteRef}
                                             fields={['geometry', "address_component", "formatted_address"]}
                                             restrictions={{ country: ['gb'] }}
                                          >
                                             <input className="register-input white-input" required placeholder="Dropoff (Type here or Pick from Map)" type="search" />
                                          </Autocomplete>
                                       </div>
                                    </div>
                                    <div className={`form-field ${name && email && phone ? 'checked' : ''}`}>
                                       <img src={ImgUser} width={64} height={64} />
                                       <div>
                                          <h4>Enter your details so we know how to contact you</h4>
                                          <PhoneInput
                                             placeholder="Phone number"
                                             value={phone}
                                             onChange={handlePhone}
                                             id="phone"
                                             required
                                             inputProps={{
                                                name: "phone",
                                                required: true,
                                             }}
                                             defaultCountry="GB"
                                             countries={["GB"]}
                                             international={true}
                                          />
                                          <input disabled={phone ? false : true} className={`register-input white-input ${phone ? '' : "bgDisable"}`} required placeholder="Name" type="text" defaultValue={name} onBlur={onChangeName} />
                                          {/* <input className="register-input white-input" required placeholder="Phone" type="tel" onChange={(e: any) => setPhone(e.target.value)} /> */}

                                          <input disabled={phone ? false : true} defaultValue={email} className={`register-input white-input ${phone ? '' : "bgDisable"}`} placeholder="Email" type="email" onBlur={(e: any) => setEmail(e.target.value)} />
                                       </div>
                                    </div>

                                    <div className={`form-field ${pickupDate && pickupTime ? 'checked' : ''}`}>
                                       <img src={ImgDate} width={64} height={64} />
                                       <div>
                                          <h4>Pickup time should be at least 6 hours from now</h4>
                                          <div className='d-flex'>
                                             <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                                <DatePicker
                                                   className='mr-1 date-field'
                                                   label="Pickup Date"
                                                   disablePast={true} 
                                                   selectedSections='all'
                                                   open={pickupDateOpened}
                                                   onAccept={(date: any) => {
                                                      setPickupDate(date);
                                                      setPickupDateOpened(false);
                                                   }}
                                                   minDate={minDate()}
                                                   onClose={() => setPickupDateOpened(false)}
                                                   slotProps={{
                                                      textField: {
                                                         onClick: () => setPickupDateOpened(true),             
                                                      },
                                                      actionBar: {
                                                         actions: ['cancel', 'clear', 'accept']
                                                      }
                                                   }}
                                                />
                                                <TimePicker
                                                   className="time-field"
                                                   label="Pickup Time"
                                                   open={pickupTimeOpened}
                                                   onAccept={(time: any) => {
                                                      setPickupTime(time);
                                                      setPickupTimeOpened(false);
                                                   }}
                                                   minTime={minTime('pickup')}
                                                   onClose={() => setPickupTimeOpened(false)}
                                                   slotProps={{
                                                      textField: {
                                                         onClick: () => setPickupTimeOpened(true),             
                                                      },
                                                      actionBar: {
                                                         actions: ['cancel', 'clear', 'accept']
                                                      }
                                                   }}
                                                />
                                             </LocalizationProvider>
                                          </div>
                                       </div>
                                    </div>

                                    <div className={`form-field ${dropoffDate && dropoffTime ? 'checked' : ''}`}>
                                       <img src={ImgDate} width={64} height={64} />
                                       <div>
                                          {/* <h4>Do you want a driver to pick you from your dropoff later?</h4> */}
                                          <div className='d-flex'>
                                             <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                                <DatePicker
                                                   className='mr-1 date-field'
                                                   label="Return Date (optional)"
                                                   disablePast={true} 
                                                   selectedSections='all'
                                                   minDate={pickupDate}
                                                   open={dropoffDateOpened}
                                                   onAccept={(date: any) => {
                                                      setDropoffDate(date);
                                                      setDropoffDateOpened(false);
                                                   }}
                                                   onClose={() => setDropoffDateOpened(false)}
                                                   slotProps={{
                                                      textField: {
                                                         onClick: () => setDropoffDateOpened(true),             
                                                      },
                                                      actionBar: {
                                                         actions: ['cancel', 'clear', 'accept']
                                                      }
                                                   }}
                                                />
                                                <TimePicker
                                                   className="time-field"
                                                   label="Return Time (optional)"
                                                   open={dropoffTimeOpened}
                                                   minTime={minTime('dropoff')}
                                                   onAccept={(time: any) => {
                                                      setDropoffTime(time);
                                                      setDropoffTimeOpened(false);
                                                   }}
                                                   onClose={() => setDropoffTimeOpened(false)}
                                                   slotProps={{
                                                      textField: {
                                                         onClick: () => setDropoffTimeOpened(true),             
                                                      },
                                                      actionBar: {
                                                         actions: ['cancel', 'clear', 'accept']
                                                      }
                                                   }}
                                                />
                                             </LocalizationProvider>
                                          </div>
                                       </div>
                                    </div>

                                    <div className={`form-field`}>
                                       <img src={ImgFeatures} width={64} height={64} />
                                       <div>
                                          <h4>Vehicle Options</h4>
                                          <select className="register-input white-input" onChange={(e) => {setFeature(parseInt(e.currentTarget.value, 10))}} placeholder="Vehicle Features">
                                             <option key={0} value={0}></option>
                                             <option key={1} value={300}>Child Seat (+£3)</option>
                                             <option key={2} value={300}>Wheelchair (+£3)</option>
                                             <option key={3} value={300}>Meet and Greet (+£3)</option>
                                          </select>
                                       </div>
                                    </div>

                                    <div className={`form-field`}>
                                       <img src={ImgAirplane} width={64} height={64} />
                                       <div>
                                          <h4>If you're arriving via air, you can provide a flight number so the driver can find you</h4>
                                          <input className={`register-input white-input`} placeholder="Flight Number (optional)" type="flight-no" />
                                       </div>
                                    </div>

                                    {serviceId ? <select className="register-input white-input" onChange={onChange} defaultValue={serviceId} hidden>
                                       {options.map((item) =>
                                          <option key={item.id} className="register-input white-input" value={item.id}>{item.title}</option>
                                       )}
                                    </select> : null}

                                    {/* <div className='d-flex justify-content-end'>
                                       <button 
                                       type="button" 
                                       id="scheduleRideButton"
                                       onClick={() => setStep(StepType.Scheduled)}>Schedule Rides</button>
                                    </div> */}

                                    {/* Scheduled bookings list */}
                                    {/* {scheduleDates.length > 0 && (
                                       <div >
                                          <div className='d-flex flex-row' style={{ backgroundColor: '#222023', border: '1px solid white' }}>
                                             <div style={{ flex: 0.01, fontWeight: 'bolder' }} className="p-1">#</div>
                                             <div style={{ flex: 0.36, fontWeight: 'bolder' }} className="p-1 text-center">Pick a date</div>
                                             <div style={{ flex: 0.30, fontWeight: 'bolder' }} className="p-1 text-center">Repeat</div>
                                             <div style={{ flex: 0.30, fontWeight: 'bolder' }} className="p-1 text-center">Return to Pickup</div>
                                          </div>

                                          {scheduleDates.map(({ date, repeat, returnToPickup }, index) => (
                                             <div className='d-flex flex-row align-items-center' key={index} style={{ borderColor: 'white', borderStyle: "solid", borderWidth: '0px 1px 1px 1px' }}>
                                                <div style={{ flex: 0.01, cursor: 'pointer' }} className='me-1 p-1 d-flex justify-content-center'>{index + 1}</div>
                                                <div style={{ flex: 0.36 }} className='p-1'>
                                                   <p style={{ backgroundColor: 'transparent', padding: '0.2rem', margin: 0, color: '#d0d0d0', fontWeight: 'bold', colorScheme: 'dark', borderWidth: 0, height: '2rem' }}>{date.substring(0, 10)} {date.substring(11, 15)}</p>
                                                </div>
                                                <div style={{ flex: 0.30 }} className='p-1 d-flex justify-content-center'>
                                                   <p>{repeat}</p>
                                                </div>
                                                <div style={{ flex: 0.30 }} className='p-1 d-flex justify-content-center'>
                                                   {returnToPickup ? 'Yes' : 'No'}
                                                </div>
                                             </div>
                                          ))}


                                          <div className='d-flex flex-row justify-content-end'>
                                             <p style={{ flex: 0.30, fontWeight: 'bolder' }}>
                                                {totalBookings} Booking(s)
                                             </p>
                                          </div>
                                       </div>
                                    )} */}

                                    <button
                                       type="submit"
                                       disabled={isButtonDisabled()}
                                       className="register-submit"
                                       style={isButtonDisabled() ? { backgroundColor: "#858585" } : {}}
                                    >{loading ? 'Processing...' : 'Book A Taxi'}</button>
                                 </form>

                                 {booking && (
                                    <div className="panel panel-success" style={{ backgroundColor: '#00c851', padding: '5px', margin: '10px 0px', borderRadius: '10px', boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)' }}>
                                       <div className="panel-heading" style={{ fontWeight: '20px', fontSize: '20px', color: 'white', padding: "0.75rem 1.25rem" }}>Your booking has been scheduled.</div>
                                       <div className="panel-body" style={{ backgroundColor: 'white', padding: '5px', borderRadius: '10px' }}><p><b>Passenger: </b>{booking.passenger}</p><p><b>Pickup: </b>{booking.pickup}</p><p><b>DropOff: </b>{booking.dropoff}</p><button style={{ display: 'flex', margin: 'auto', borderColor: 'transparent', borderRadius: '10px', padding: '10px', backgroundColor: '#007bff', color: 'white' }} onClick={() => { history.push(`/track/${booking.code}`) }}><b>Track Booking</b></button></div>
                                    </div>
                                 )}
                              </>
                           )}
                           {step === StepType.Stripe && (
                              <Elements stripe={stripePromise} options={stripeOptions}>
                                 {clientSecret && <CheckoutForm passengerId={passenger?.id as string} bookingCode={booking?.code} clientSecret={clientSecret} onCardAdded={onCardAdded} />}
                                 {getTotalEstimate() && <p className="hint">Your card will be charged with {estimateText()}</p>}
                              </Elements>
                           )}
                           {step === StepType.Finalize && (
                              <div>
                                 <h4>Booking Details</h4>

                                 <div className="my-2"><strong>Pickup location</strong>: {pickupPlaceFormatted?.text}</div>
                                 <div className="my-2"><strong>Dropoff location</strong>: {dropoffPlaceFormatted?.text}</div>
                                 <div className="my-2"><strong>Estimated Fare</strong>: {estimateText()}</div>
                                 <button className="register-submit" onClick={createBooking}>Complete Booking</button>
                              </div>
                           )}
                           {step === StepType.Scheduled && (
                              <div >
                                 <div className='d-flex flex-row' style={{ backgroundColor: '#222023', border: '1px solid white' }}>
                                    <div style={{ flex: 0.01, fontWeight: 'bolder' }} className="p-1">#</div>
                                    <div style={{ flex: 0.36, fontWeight: 'bolder' }} className="p-1 text-center">Pick a date</div>
                                    <div style={{ flex: 0.30, fontWeight: 'bolder' }} className="p-1 text-center">Repeat</div>
                                    <div style={{ flex: 0.30, fontWeight: 'bolder' }} className="p-1 text-center">Return to Pickup</div>
                                 </div>

                                 {scheduleDates.length > 0 ? scheduleDates.map(({ date, repeat, returnToPickup }, index) => (
                                    <div className='d-flex flex-row align-items-center' key={index} style={{ borderColor: 'white', borderStyle: "solid", borderWidth: '0px 1px 1px 1px' }}>
                                       <div style={{ flex: 0.01, cursor: 'pointer' }} className='me-1 p-1 d-flex justify-content-center' onClick={() => handleScheduleRowUpdate(undefined, index, "delete")}>X</div>
                                       <div style={{ flex: 0.36 }} className='p-1'>
                                          <input type="datetime-local" value={date} onChange={(e) => handleScheduleRowUpdate(e.target.value, index, "date")}
                                             style={{ backgroundColor: 'transparent', padding: '0.2rem', margin: 0, color: '#d0d0d0', fontWeight: 'bold', colorScheme: 'dark', borderWidth: 0, height: '2rem' }} />
                                       </div>
                                       <div style={{ flex: 0.30 }} className='p-1 d-flex justify-content-center'>
                                          <select value={repeat}
                                             style={{ backgroundColor: 'transparent', padding: '0.2rem', margin: 0, color: '#d0d0d0', fontWeight: 'bold', colorScheme: 'dark', borderWidth: 0, height: '2rem', width: '100%' }}
                                             onChange={(e) => handleScheduleRowUpdate(e.target.value, index, "repeat")}>
                                             <option style={{ backgroundColor: '#3B3B3B' }} value={Repeat.NONE}>None</option>
                                             <option style={{ backgroundColor: '#3B3B3B' }} value={Repeat.DAILY}>Daily</option>
                                             <option style={{ backgroundColor: '#3B3B3B' }} value={Repeat.WEEKLY}>{date ? `Every ${new Date(date).toLocaleString('en-us', { weekday: 'long' })}` : 'Weekly'}</option>
                                             <option style={{ backgroundColor: '#3B3B3B' }} value={Repeat.MONTHLY}>{date ? `Every ${nth(new Date(date).getDate())} of Month` : "Every Month"}</option>
                                             <option style={{ backgroundColor: '#3B3B3B' }} value={Repeat.YEARLY}>{"Every year"}</option>
                                          </select>
                                       </div>
                                       <div style={{ flex: 0.30 }} className='p-1 d-flex justify-content-center'>
                                          <label className="switch bordor-bottom">
                                             <input checked={returnToPickup} onChange={(e) => handleScheduleRowUpdate(e.target.checked, index, "returnToPickup")} type="checkbox" />
                                             <span className="slider round"></span>
                                          </label>
                                       </div>
                                    </div>
                                 )) : (
                                    <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 20 }}>No Scheduled Rides</p>
                                 )}

                                 <div className='d-flex flex-row'>
                                    <div className='p-1' style={{ flex: 0.01 }}></div>
                                    <div className='p-1 d-flex justify-content-center' style={{ flex: 0.36 }}>
                                       <input id="select-schedule-time"
                                          style={{ backgroundColor: 'transparent', padding: '0.2rem', margin: 0, color: '#d0d0d0', fontWeight: 'bold', colorScheme: 'dark', borderWidth: 0, height: '2rem' }}
                                          type="datetime-local" value={dateRef.current} />
                                    </div>
                                    <div className='p-1 d-flex justify-content-center align-items-center' style={{ flex: 0.30, fontWeight: 'bolder' }}>
                                       {totalBookings} Booking(s)
                                    </div>
                                    <div className='register-submit p-0 d-flex justify-content-center align-items-center' style={{ flex: 0.30 }} onClick={() => {
                                       //@ts-ignore
                                       const scheduleDate = document.getElementById('select-schedule-time').value;
                                       if (scheduleDate && !checkDateTimeOverlap(scheduleDate)) {
                                          const tmp = [...scheduleDates];
                                          tmp.push({ date: scheduleDate, repeat: Repeat.NONE, returnToPickup: false });
                                          setScheduleDates(tmp);
                                       }
                                       else alert('The Date is overlapping with the previous entries!');
                                    }}>Add</div>
                                 </div>

                                 <div className='p-1 d-flex flex-row justify-content-center align-items-center mt-5'>
                                    <button type="button" className='btn-primary px-5 py-2 rounded' onClick={() => setStep(StepType.Personal)}>DONE</button>
                                 </div>
                              </div>
                           )}
                           <p className="register-form-terms"></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6 margin-top-40">
                     <div className="intro d-none d-md-block">
                        <h1>Book your ride</h1>
                        <p>Book your ride with ease and arrive in comfort and style using our simple booking form for the most reliable taxi cab service in Manchester</p>
                     </div>
                     <div className="main-map">
                        <Autocomplete
                           ref={mapAutocompleteRef}
                           fields={['geometry', "address_component", "formatted_address"]}
                           restrictions={{ country: ['gb'] }}
                           onPlaceChanged={ mapSearch }
                        >
                           <input className="register-input white-input" placeholder="Search map..." type="text" />
                        </Autocomplete>
                        { (mapSearchPlace || (pickupPlace && dropoffPlace)) && (
                        <div className="map-search-btns">
                           <a className={pickupPlace ? 'green' : ''} href="javascript:void()" onClick={() => setSearchAs('pickup')}>Set as Pickup</a>
                           <a className={dropoffPlace ? 'green' : ''} href="javascript:void()" onClick={() => setSearchAs('dropoff')}>Set as Dropoff</a>
                           { pickupPlace && <a href="javascript:void()" onClick={() => clearPlace('pickup')}>Clear Pickup</a> }
                           { dropoffPlace && <a href="javascript:void()" onClick={() => clearPlace('dropoff')}>Clear Dropoff</a> }
                        </div>
                        )}
                        <GoogleMap
                           mapContainerStyle={{
                              width: '100%',
                              height: 530,
                           }}
                           options={{
                              gestureHandling: 'greedy',
                           }}
                           center={center}
                           zoom={zoom}
                           onLoad={onLoad}
                           onClick={onClick}
                           onUnmount={onUnmount}
                           onDrag={onDrag}
                           onDragEnd={onCenterChange}
                        >
                           {findArea && findArea.GpsNearBy.length > 0 ? findArea.GpsNearBy.map((area: any) => {
                              return <Marker key={area.lat} position={{ lat: area.lat, lng: area.lng }} zIndex={1000000000} icon={{
                                 url: car,
                                 scaledSize: size,
                                 anchor: point,
                                 rotation: area.heading
                              }} visible={true} />
                           }) : null
                           }
                           {!directions && pickupPlace && pickupPlace.geometry && <Marker position={pickupPlace.geometry.location as google.maps.LatLng} />}
                           {!directions && dropoffPlace && dropoffPlace.geometry && <Marker position={dropoffPlace.geometry.location as google.maps.LatLng} />}
                           {directions && <DirectionsRenderer options={{ directions, preserveViewport: true }} />}
                           {directions && <TrafficLayer />}
                           {mapSearchPlace && <Marker ref={centerMarkerRef} position={(mapSearchPlace as any).geometry.location} animation={google.maps.Animation.DROP} />}
                        </GoogleMap>

                     </div>
                     { routes.map(route => (
                     <div className={'estimate' + (getTotalDistance() ? ' show' : '')}>
                        <div className="estimate-box hero">
                           <p>{estimateText(route)}</p>
                        </div>
                        <div className="estimate-box">
                           <h4>Distance</h4>
                           <p>{distanceText(route.distance ? route.distance.value : 0)} </p>
                        </div>
                        <div className="estimate-box">
                           <h4>Duration</h4>
                           <p>{durationText(route.duration ? route.duration.value : 0)}</p>
                        </div>
                        <div className="estimate-box">
                           <h4>Traffic</h4>
                           <p>{trafficText(route.duration_in_traffic ? route.duration_in_traffic.value - (route.duration ? route.duration.value : 0) : 0)}</p>
                        </div>
                     </div>
                     ))}
                  </div>
               </div>
            </div>
         </section>
         <Footer />
      </LoadScript>
   );
}
