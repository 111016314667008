import React from 'react';
import { Footer } from '../footer/Footer'

export function Contact() {
    return (
        <>
            <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title">Contact Us</h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Thank you for your interest in Manchester Airport Taxi Service. We value your feedback, inquiries, and suggestions. To get in touch with us, please find our contact information below.</p>
                            <h2>Contact Details</h2>
                            <p className="section-subtitle">If you have any questions, require assistance, or would like to make a booking, our dedicated customer support team is ready to assist you. You can reach us through the following channels:</p>
                            <h2>Phone</h2>
                            <p className="section-subtitle">For immediate assistance, please give us a call at <a href="tel:+447424118933">0742 411 8933</a>. Our phone lines are open 24/7, ensuring that you can reach us whenever you need our services or have any inquiries.</p>
                            <h2>E-Mail</h2>
                            <p className="section-subtitle">If you prefer to contact us via email, you can send your queries to <a href="mailto:contact@manchesterairportaxi.com">contact@manchesterairportaxi.com</a> We strive to respond to all email inquiries promptly and provide you with the information you need.</p>
                            <h2>Follow Us on social media</h2>
                            <p className="section-subtitle">Stay updated with the latest news, offers, and announcements by following us on social media. Connect with us on:</p>
                            <ul>
                                <li><a href="https://www.facebook.com/profile.php?id=100091840127800" target="_blank">Facebook</a></li>
                                <li><a href="https://www.linkedin.com/company/manchester-airport-taxi-2023/" target="_blank">LinkedIn</a></li>
                                <li><a href="https://www.instagram.com/manchesterairporttaxi2023/" target="_blank">Instagram</a></li>
                            </ul>
                            <p className="section-subtitle">We regularly share valuable content, travel tips, and updates on our social media platforms, so make sure to join our online community.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
} 