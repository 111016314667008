import React from 'react';
import { Footer } from '../footer/Footer';
import img4 from './../../img/play-store.png';
import img5 from './../../img/app-store.png';

export function Passengers(props: any) {

    const login = () =>{
        props.history.push('/login/')
    }

    return (
        <>
            <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title">Passengers</h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">
                            We understand the importance of convenience and ease when it comes to airport transportation. That's why we offer multiple ways for passengers to book and track their rides, including our Android and iOS apps, phone booking, and website booking.
                            </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">
                            Our user-friendly apps allow you to book your ride and track your driver in real-time, so you can have peace of mind knowing that your transportation is taken care of. You can also view your ride history, manage your bookings, and make payments through the app.
                            </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">
                            If you prefer to book your ride over the phone, our friendly customer service team is available 24/7 to assist you. Simply give us a call, and we'll be happy to arrange your transportation and provide you with any information you need.
                            </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">
                            Our website also provides a quick and easy way to book your ride. Simply visit our website, enter your pickup and drop-off locations, select your vehicle type, and make your booking. You can also view our fares, read about our services, and learn more about our company on our website.
                            </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p>
                                <a className="store-btn android" href="#"><img src={img4} alt="play store" /></a>
                                <a className="store-btn" href="#"><img src={img5} alt="app store" /></a>
                            </p>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}