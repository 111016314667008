import React from 'react';

export function AboutContent() {
    return (
        <section id="about-content" className="about-section">
            <div className="container" >
                <h1 className="section-title margin-top-40">About Us</h1>
            </div>

            <div className="container text-justify">
                <h2>Manchester Airport Taxi Service</h2>
                <p className="mb-30 section-subtitle">At Manchester Airport Taxi Service, we are dedicated to providing you with a reliable and convenient transportation solution for your airport transfer needs. With a focus on exceptional service, professionalism, and customer satisfaction, we strive to make your journey comfortable, safe, and stress-free.</p>
                <h2>Mission</h2>
                <p className="mb-30 section-subtitle">We have a fleet of well-maintained vehicles to accommodate groups of any size. Our drivers are highly skilled and experienced, and they are committed to providing safe and courteous transportation services. We also use the latest technology to track flights, so you can be sure that we'll be there to pick you up on time, even if your flight is delayed.</p>
                <h2>Values</h2>
                <p className="mb-30 section-subtitle">
                Reliability: We understand the importance of being dependable and trustworthy. You can rely on us to be there on time, every time, ensuring a smooth and timely journey to or from the airport. Our commitment to reliability is ingrained in every aspect of our service, from the professionalism of our drivers to the maintenance of our vehicles.
                <ol>
                    <li><b>Customer Satisfaction:</b> Our customers are at the heart of everything we do. We go the extra mile to ensure your satisfaction by providing personalised attention, listening to your needs, and delivering a service that exceeds your expectations. Your comfort, safety, and overall experience matter to us, and we strive to create a positive and memorable journey for each and every customer.</li>
                    <li><b>Professionalism:</b> We pride ourselves on maintaining a team of highly professional and courteous drivers who uphold the highest standards of conduct. Our drivers are well-trained, knowledgeable, and dedicated to providing exceptional customer service. They are committed to making your airport transportation experience pleasant, ensuring a comfortable and enjoyable ride.</li>
                    <li><b>Commitment to Safety:</b> Safety is our utmost priority. We adhere to strict safety protocols and maintain our vehicles to the highest standards. Our drivers are experienced and well-versed in defensive driving techniques, ensuring your safety throughout the journey. We are committed to providing a secure and worry-free transportation experience for our passengers.</li>
                </ol>
                </p>
                <h2>Contact Us Today</h2>
                <p className="mb-30 section-subtitle">Learn more about Manchester Airport Taxi Service and experience our reliable and customer-focused transportation service. Visit our website or contact our friendly customer support team for any inquiries or to make a booking. We look forward to serving you and ensuring your airport transfer is a seamless and enjoyable experience.</p>
                
            </div>

        </section>
    )

} 
