import React from 'react';
// import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { ApiService } from '../../../service/api.service';
import { authentication } from '../../../service/auth.service';
import { CorporateUser } from '../../entities/corporate-user.entity';

interface props {
  passengerId?: string,
  corporateUser?: CorporateUser,
  bookingCode?: string,
  clientSecret: string,
  onCardAdded?: Function;
}

export const CheckoutForm = ({ passengerId, clientSecret, corporateUser, onCardAdded }: props) => {

  // Pass the appearance object to the Elements instance
  //   const elements = stripe.elements({clientSecret, appearance});
  const stripe = useStripe();
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      setSuccessMessage(""); setLoading(true);
      setErrorMessage("");
      if (elements == null) {
        setLoading(false);
        return;
      }
      if (cardholderName === "") {
        setErrorMessage("Please enter the name of card holder")
        setLoading(false);
        return;
      }
      const cardElement = elements.getElement(CardElement);
      console.log("card elements", elements.getElement(CardElement), elements)
      if (stripe && cardElement && elements) {
        const { error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: cardholderName,
          },
        });

        if (error) {
          console.error("Error occured while adding stripe card: ", error)
          setErrorMessage(error.message as string);
          setLoading(false);
          return;
        }

        const stripeResponse = await stripe
          .confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })

        if (stripeResponse?.error) {
          throw stripeResponse.error;
        }

        let corporateAccountId = corporateUser?.corporateAccount?.id;
        switch (true) {
          case passengerId !== undefined:
            const paymentId = stripeResponse.setupIntent.payment_method;

            await ApiService.post('passenger/addStripeCard', {
              passengerId,
              paymentId
            });

            break
          case corporateAccountId !== undefined:

            const responseData = await ApiService.post('corporateAccount/addStripeCard', {
              corporateAccountId,
              paymentId: stripeResponse.setupIntent?.payment_method
            });

            if (responseData?.data?.error || responseData.status !== 201) {
              setLoading(false);
              setErrorMessage(responseData?.data?.error || 'Some issue in adding stripe card');
              return;
            }

            const corporate = authentication.getCorporateUser()
            corporate.corporateAccount = responseData.data;
            authentication.onAuthenticationCorporate(corporate);
            setTimeout(() => {
              window.location.reload();
            }, 500);

            break;
        }

        setLoading(false);
        setSuccessMessage("Card Added SuccessFully");
        onCardAdded && onCardAdded();
      }
    }
    catch (error: any) {
      setLoading(false);

      if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(error?.response?.data?.message);
      }
    }
  };

  return (
    // <form onSubmit={handleSubmit}>
    <form onSubmit={handleSubmit}>
      <input className="form-control bg-white inputshow p-2" autoFocus placeholder="Name on Card" type="text" name='name' onChange={(e) => setCardholderName(e.target.value)} />
      <fieldset className="FormGroup inputshow">
        <div className="FormRow my-2">
          <CardElement options={OPTIONS} className="form-control bg-white p-2" />
        </div>
      </fieldset>
      {!loading ? <button disabled={!stripe || !elements} className='texisPlusBtn '>Make Payment Now</button> :
        <button disabled className='texisPlusBtn '>Processing...</button>}
      {successMessage && <h4 style={{ color: 'green' }}>{successMessage}</h4>}
      {errorMessage && <h4 style={{ color: 'red' }}>{errorMessage}</h4>}
    </form>
    /* <CardElement options={OPTIONS} className="form-control bg-white" />
    <button type="submit" >
      Pay
    </button> */
    // </form>
  );
};