import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';

export function Home() {

   const [flashMessage, setFlashMessage] = useState<string>();
   useEffect(() => {
      const _flashMessage = localStorage.getItem('flash_message');
      if (_flashMessage) {
         setFlashMessage(_flashMessage);
         localStorage.removeItem('flash_message');
      }
   }, []);
   return (
      <>
         <section className="section-yellow home-section" id="home">
            <div className="container">
               <div className="row">
                  <div className="home-intro col-md-4 padding-top-40">
                     <h1>Book your ride</h1>
                     { flashMessage && <p className="flash-message">{ flashMessage }</p>}
                     <p className="margin-bottom-60">Book your ride with ease and arrive in comfort and style using our simple booking form for the most reliable taxi cab service in Manchester</p>
                     <Link to="/book/" className="btn-hero">Book Now</Link>
                  </div>
               </div>
            </div>
         </section>
         <section className="section-yellow home-section" id="home-text">
            <div className="container">
               <div className="row">

                  <div className="text-section">
                     <h2>Welcome to Manchester Airport Taxi Service - Your Reliable Transportation Solution</h2>
                     <p>Looking for a hassle-free and comfortable airport transfer experience? Look no further! Manchester Airport Taxi Service is here to provide you with a top-notch transportation service that ensures a smooth and reliable journey to and from the airport.</p>
                  </div>

                  <div className="text-section">
                     <h2>Why Choose Manchester Airport Taxi Service?</h2>
                     <p>At Manchester Airport Taxi Service, we understand the importance of 24/7 (4 hours’ notice needed) availability, professional drivers, and timely pickups when it comes to airport transportation. With our years of experience in the industry, we have built a solid reputation for delivering exceptional service to our valued customers.</p>
                  </div>

                  <div className="text-section">
                     <h2>24/7 Availability for Your Convenience</h2>
                     <p>We know that travel plans don't always fit within regular business hours. That's why Manchester Airport Taxi Service operates around the clock, 24 hours a day, 7 days a week. Whether you have an early morning flight, a late-night arrival, or any time in between, we are here to serve you. Our reliable service ensures that you can count on us whenever you need transportation to or from the airport</p>
                  </div>

                  <div className="text-section">
                     <h2>Professional Drivers Ensuring Your Safety and Comfort</h2>
                     <p>Your safety and comfort are our top priorities. That's why we have a team of professional drivers who are highly skilled, experienced, and dedicated to providing you with a superior transportation experience. Our drivers undergo rigorous training, possess excellent driving records, and are committed to delivering outstanding customer service. You can trust them to navigate the roads with expertise, ensuring a smooth and secure journey.</p>
                  </div>

                  <div className="text-section">
                     <h2>Timely Pickups for a Stress-Free Experience</h2>
                     <p>We understand the importance of being on time, especially when it comes to catching a flight or arriving at your destination promptly. With Manchester Airport Taxi Service, you can rest assured that we will be there at your designated pickup location at the agreed-upon time. Our drivers are punctual and efficient, allowing you to relax and enjoy a stress-free journey.</p>
                  </div>

                  <div className="text-section">
                     <h2>A Wide Range of Vehicles to Suit Your Needs</h2>
                     <p>We believe in providing options that cater to your unique requirements. Whether you're travelling alone, with a group, or have specific luggage needs, our diverse fleet of well-maintained vehicles has got you covered. From spacious sedans to luxurious SUVs, we offer a range of choices to ensure your comfort and convenience.</p>
                  </div>

                  <div className="text-section">
                     <h2>Transparent Pricing and Easy Booking</h2>
                     <p>We believe in transparency and providing our customers with the best value for their money. Our fares are competitive and clearly laid out, so you know exactly what to expect. You can conveniently estimate the fare for your journey using our online fare calculator. Booking with Manchester Airport Taxi Service is a breeze, with our user-friendly website and dedicated customer support team ready to assist you.</p>
                  </div>

                  <div className="text-section">
                     <h2>Contact Us Today for Bookings</h2>
                     <p>Ready to experience the convenience and reliability of Manchester Airport Taxi Service? Explore our website for more information about our services, fares, and fleet. Should you have any questions or require assistance, our friendly customer support team is just a phone call away.</p>
                     <p>Book your airport taxi with Manchester Airport Taxi Service today and enjoy the peace of mind that comes with 24/7 availability, professional drivers, timely pickups, and a wide range of vehicles. We are committed to providing you with a seamless and exceptional travel experience.</p>
                  </div>

               </div>
            </div>
         </section>
      </>)
}
