import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { authentication } from '../../service/auth.service';
import { CorporateAccount } from '../entities/corporate-account.entity';
import { CorporateUser } from '../entities/corporate-user.entity';
import { getStorageUrl } from '../util/file.util';
import logo from './../../img/logo.png';

interface props {
   isPassenger?: boolean
   isCorporate?: boolean
   corporateUser?: CorporateUser
}
export enum ProfilePicSize {
   List = "admin_list",
   Single = "admin_single",
 }
export function Navbar({ isPassenger, isCorporate,corporateUser }: props) {

   const [collapsed, setCollapsed] = useState(true);
   const [menu, setMenu] = useState<any>();

   useEffect(() => {
      let menu;
      
      if (isPassenger) {

         menu = [
            {
               route: '/',
               title: 'Home',
            },
            {
               route: '/about/',
               title: 'About',
            },
            {
               route: '/fares/',
               title: 'Fares',
            },

            {
               route: '/operators/',
               title: 'Operators',
            },
            {
               route: '/news/',
               title: 'News',
            },
            {
               route: '/contact/',
               title: 'Contact',
            },
            {
               route: '/links/',
               title: 'Links',
            },
            {
               route: '/passenger-details/',
               title: 'Passenger',
            },
            {
               route: '/logout',
               title: 'Logout',
            },
         ]

      }
      else if (isCorporate && corporateUser?.role === 'staff') {
         menu = [
            {
               route: '/',
               title: 'Home',
            },
            {
               route: '/corporate/booking',
               title: 'Bookings',
            },
            
            {
               route: '/corporate/scheduled/',
               title: 'Scheduled Bookings',
            },
           
            {
               route: '/transaction/list/',
               title: 'Transactions',
            },
            {
               route: '/corporate/payment/',
               title: 'Payment',
            },
           
            {
               route: '/logout/',
               title: 'Logout',
            },
         ]
      }
      else if (isCorporate && corporateUser?.role === 'admin') {
         menu = [
            {
               route: '/',
               title: 'Home',
            },
            {
               route: '/corporate/booking',
               title: 'Bookings',
            },
            
            {
               route: '/corporate/scheduled/',
               title: 'Scheduled Bookings',
            },
           
            {
               route: '/transaction/list/',
               title: 'Transactions',
            },
            {
               route: '/corporate/payment/',
               title: 'Payment',
            },
            {
               route: '/corporate/users/',
               title: 'Users',
            },
            {
               route: '/logout/',
               title: 'Logout',
            },
         ]
      }
      else {

         menu = [
            {
               route: '/',
               title: 'Home',
            },
            {
               route: '/about/',
               title: 'About',
            },
            {
               route: '/fares/',
               title: 'Fares',
            },
            {
               route: '/passengers/',
               title: 'Passengers',
            },
            // {
            //    route: '/drivers/',
            //    title: 'Drivers',
            // },
            // {
            //    route: '/operators/',
            //    title: 'Operators',
            // },
            // {
            //    route: '/news/',
            //    title: 'News',
            // },
            {
               route: '/contact/',
               title: 'Contact',
            },
            // {
            //    route: '/links/',
            //    title: 'Links',
            // },
            // {
            //    route: '/corporate/login/',
            //    title: 'Corporate',
            // },

         ]

      }

      setMenu(menu)


   }, [isPassenger, isCorporate,corporateUser])

   const getLogoPic = (corporate: CorporateAccount, type: ProfilePicSize): string|undefined => {
      if (corporate?.logoFile?.id && corporate?.id) {
        const imgUrl= `${getStorageUrl()}/corporateAccount/${corporate.id}/logo/${corporate.logoFile?.id
          }/${type}.${corporate.logoFile?.extension}`;
          return imgUrl
      } else {
        return undefined;
      }
    };

   return (
      <header className="header">
         <nav className="navbar navbar-default navbar-fixed-top navbar-dark">
            <div className="container">
               <nav className="navbar navbar-expand-lg">
                  <Link className="navbar-brand" to="/">
                     <img className="logo" src={corporateUser?.corporateAccount?.logoFile? getLogoPic(corporateUser?.corporateAccount,ProfilePicSize.List):logo} alt="logo" width={corporateUser?.corporateAccount.logoFile?`100px`:`400px`} height={'100px'} />
                  </Link>
                  <button
                     className="navbar-toggler collapsed"
                     type="button"
                     data-toggle="collapse"
                     data-target="#navbarCollapse"
                     aria-controls="navbarCollapse"
                     aria-expanded="false"
                     aria-label="Toggle navigation"
                     onClick={() => setCollapsed(!collapsed)}
                  >
                     <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
                  </button>
                  <div className={'navbar-collapse' + (collapsed ? ' collapse' : '')}>
                     <ul className="navbar-nav ml-auto">
                        {menu?.map(({ route, title }: any) => (
                           <li key={route} className="nav-item">
                              {route === "/logout" ? <Link className="nav-link" to={"/"} onClick={() => { authentication.PassengerLogout(); window.location.replace("/") }}>{title}</Link> : <Link className="nav-link" to={route} onClick={() => setCollapsed(true)}>{title}</Link>}
                           </li>
                        ))}
                     </ul>
                  </div>
               </nav>
            </div>
         </nav>
      </header>

   )
} 