import React from 'react';
import { Link } from "react-router-dom";
import ImgFb from '../../img/social/fb.png';
import ImgIg from '../../img/social/ig.png';
import ImgLi from '../../img/social/li.png';

export function Footer() {
   return (
      <div className="footer container-fluid">
         <div className="container">
            <div className="row">
               <div className="col-md-6 footer-bottom text-left">
                  <a href="https://www.facebook.com/profile.php?id=100091840127800" target="_blank">
                     <img src={ImgFb} width={32} height={32} /></a>
                  <a href="https://www.instagram.com/manchesterairporttaxi2023/" target="_blank">
                     <img src={ImgIg} width={32} height={32} /></a>
                  <a href="https://www.linkedin.com/company/manchester-airport-taxi-2023/?viewAsMember=true" target="_blank">
                     <img src={ImgLi} width={32} height={32} /></a>
                  <Link to="/privacy-policy/" className="footer-links">Privacy Policy</Link>
               </div>
               <div className="col-md-6 footer-bottom text-right">
                  <p>Copyright © 2023 <strong>Manchester Airport Taxi</strong></p>
               </div>
            </div>
         </div>
      </div>
   )
}
