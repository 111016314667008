import React from 'react';
import { Home } from './sections/Home';
// import { Drivers } from './sections/Drivers';
import { authentication } from '../../service/auth.service';
import { Footer } from '../footer/Footer';

export function LandingPage() {



    return (
        <>
            <Home />
            {!authentication.getCorporateUser() ? <> 
                {/* <About /> */}
                {/* <Features /> */}
                {/* <Drivers/> */}
                {/* <Newsletter /> */}
                <Footer /></> : <Footer />
            }
        </>
    )
}
