export function to12HrFormat(value: number): string {
  let hour24str = String(value).padStart(2, '0');
  hour24str = `${hour24str}:00:00`;
  return new Date(`1970-01-01T${hour24str}Z`)
    .toLocaleTimeString(undefined,
      {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      }
    );
}

export const convertDateToString = (value: Date) => {
  const padValue = (number: number) => {
    return number.toString().padStart(2, '0');
  }

  const date = `${value.getFullYear()}-${padValue(value.getMonth() + 1)}-${padValue(value.getDate())}`
  const time = `${padValue(value.getHours())}:${padValue(value.getMinutes())}`

  return `${date}T${time}`;
}

export const getLaterDate = (differenceInMinutes: number) => {
  return convertDateToString(new Date(Date.now() + (differenceInMinutes * 60 * 1000)));
}

export const stringToDate = (value: string): Date => {
  return new Date(value);
}

export const isFifteenMinutesLater = (time: Date | string) => {
  let date: Date;

  if (typeof time === 'string') {
    date = new Date(time);
  } else {
    date = time;
  }

  return date.getTime() - new Date().getTime() > (15 * 60 * 1000);
}